import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

import Cover from '../components/Cover'
import VideoBox from '../components/VideoBox'
import CTA from '../components/Cta'

import coverImage from '../images/diversificazione-portafoglio.jpg'

import posterDiversificare from '../images/poster-dividere-per-tutelare.png'

const Impulsivita = () => (
  <Layout>
    <SEO title="Diversificazione del portafoglio" />
    <Cover
      title="Diversificazione del&nbsp;portafoglio"
      color="white"
      bg={coverImage}
      icon=""
      link="#video"
    >
      <p>
        Quante volte nella vita farebbe comodo avere un piano B? Di certo quando
        si tratta di investire i propri risparmi è più che consigliabile
        prendere in considerazione diverse ipotesi, come racconta il breve video
        qui sotto. Per un semplice motivo: controbilanciare i possibili rischi
        di perdite con diverse alternative di guadagno, magari minore ma più
        sicuro. Più sicuro ancora è rivolgersi a un consulente finanziario per
        farlo.
      </p>
    </Cover>
    <VideoBox ytID="eTSVEhcat5w" poster={posterDiversificare} />
    <CTA />
  </Layout>
)

export default Impulsivita
